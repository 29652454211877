<template>
    <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">All Roles</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <button class="btn btn-info float-right" @click="showCreateModal"><i class="fas fa-plus-circle"></i> Create</button>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-overlay :show="loader">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th style="width:10%">Name</th>
                                            <th style="width:69%">Status</th>
                                            <th style="width:8%">Created By</th>
                                            <th style="width:8%">Updated By</th>
                                            <th style="text-align:center;width:5%">Action</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Created By</th>
                                            <th>Updated By</th>
                                            <th style="text-align:center;">Action</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        <tr v-for="(role, index) in listData" :key="index">
                                            <td>{{ locale == 'bn' ? role.name_bn : role.name }}</td>  
                                            <td>{{ role.status }}</td>
                                            <td>{{ role.createdby ? role.createdby.name : '' }}</td>    
                                            <td>{{ role.updatedby ? role.updatedby.name : '' }}</td> 
                                            <td style="text-align:center;">                                                                       
                                                <button class="btn btn-warning btn-sm mr-1" @click="editModal(role)" title="Edit"><i class="fas fa-pencil-alt"></i></button>
                                                <!-- <button class="btn btn-danger btn-sm" @click="deleteModal(key, role.id)" title="Delete"><i class="fas fa-trash"></i></button> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="mt-3">
                                <b-pagination
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                />
                            </div>
                        </b-overlay>
                    </div>
                </div>
            </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
    <div v-if="createModal" class="modal fade show" style="display:block" tabindex="-1" id="addHubModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header bg-info text-white">
                    <h5 class="modal-title text-center w-100">Add New Role</h5>
                    <button type="button" class="close" aria-label="Close" @click="cancelModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <b-overlay :show="loader">
                        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(createRole)">
                                <div class="row">
                                    <div class="col">
                                        <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                            <div class="form-group">
                                                <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="name" v-model="form.name" class="form-control" placeholder="Name">
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col">
                                        <ValidationProvider name="Name Bn" vid="name_bn" v-slot="{errors}" rules="required">
                                            <div class="form-group">
                                                <label for="name_bn">Name Bn <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="name" v-model="form.name_bn" class="form-control" placeholder="Name">
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ValidationProvider name="Statuses" vid="status_id" v-slot="{errors}" rules="required">
                                            <div class="form-group">
                                                <label for="status_id">Status <span class="text-danger" title="Required">*</span></label>
                                                <div v-for="(item, index) in statusList" :key="index">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input" type="checkbox" :id="'customCheckbox' + index" checked="" :value="item.id" v-model="form.status_id">
                                                        <label :for="'customCheckbox' + index" class="custom-control-label">{{ item.text }}</label>
                                                    </div>
                                                </div>
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </b-overlay>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click.prevent="createRole">Save</button>
                    <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="roleEditModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header bg-info text-white">
                    <h5 class="modal-title text-center w-100">Edit Role</h5>
                    <button type="button" class="close" aria-label="Close" @click="cancelModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <b-overlay :show="loader">
                        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(updateRole)">
                                <div class="row">
                                    <div class="col">
                                        <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                            <div class="form-group">
                                                <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Name">
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col">
                                        <ValidationProvider name="Name Bn" vid="name_bn" v-slot="{errors}" rules="required">
                                            <div class="form-group">
                                                <label for="name_bn">Name Bn <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="name_bn" v-model="form_edit.name_bn" class="form-control" placeholder="Name">
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <ValidationProvider name="Statuses" vid="status_id" v-slot="{errors}" rules="required">
                                            <div class="form-group">
                                                <label for="status_id">Status <span class="text-danger" title="Required">*</span></label>
                                                <div v-for="(item, index) in form_edit.statusList" :key="index">
                                                    <div class="custom-control custom-checkbox">
                                                        <input class="custom-control-input" type="checkbox" :id="'customCheckbox' + index" :checked="item.is_check" :value="item.id" v-model="form_edit.status_id">
                                                        <label :for="'customCheckbox' + index" class="custom-control-label">{{ item.text }}</label>
                                                    </div>
                                                </div>
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </b-overlay>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click.prevent="updateRole">Update</button>
                    <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="roleDeleteModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-default" role="document">
            <div class="modal-content">
                <div class="modal-header bg-info text-white">
                  <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                </div>
                <div class="modal-body">
                    <input type="hidden" name="del_id" />
                    <h3 class="text-center">Are you sure to delete ?</h3>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click="destroyRole">Delete</button>
                    <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
    import config from '@/config'
    export default {
        name:'Role',
        data(){
            return {        
                loader: false,         
                roles:{},
                permissions:[],
                editPermissions:[],
                form:{
                    name    : '',
                    name_bn : '',
                    status_id: []
                },
                form_edit:'',
                errors:[],
                createModal:false,
                roleEditModal:false,
                roleDeleteModal:false,
                rider_id:'',
                key:'',
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created(){
            this.loadData();
        },
        computed : {
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            },
            statusList () {
                return this.$store.state.statusList
            },
            locale () {
                return this.$store.state.language
            }
        },
        watch: {
            stateReload : function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadData()
                }
            }
        },
        methods:{
            searchData () {
                this.loadData()
            },
            async loadData(){      
                this.loader = true           
                const response = await config.getData('roles')
                this.loader = false  
                if (response) {
                     this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                } else {
                    this.$store.dispatch('setList', [])
                }
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.perPage = data.per_page
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            getRelationalData (data) {
                return data.map(item => {
                    const statusNames = item.statuses.map(tmp => {
                        const statusText = this.statusList.find(status => status.id === tmp.status_id)
                        return Object.assign({ status_name: statusText.text })
                    })
                    const finalStatusNames = statusNames.map(item => { return item.status_name })
                    return Object.assign({}, item, { status: finalStatusNames.join(', ') } )
                })
            },
            showCreateModal(){
                this.createModal = true;
                document.body.classList.add("modal-open");
            },
            async createRole(){
                this.loader = true
                this.$store.dispatch('stateReload', true)
                const response = await config.postData('roles/store', this.form)  
                this.loader = false              
                if(response.status == 201){ 
                    this.$store.dispatch('commonObjLoad', true)     
                    this.createModal = false  
                    this.$toast.success({
                        title: 'Success',
                        message: 'Role created successfully',
                        color: '#218838'
                    }) 
                    this.loadData()
                }else{
                    this.$refs.form.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })  
                }                
            },
            cancelModal(){
                this.createModal = false;
                this.roleEditModal = false;
                this.roleDeleteModal = false;
                document.body.classList.remove("modal-open");
            },
            editModal(role){
                this.roleEditModal = true
                this.form_edit = this.selectedCheck(role)
                document.body.classList.add("modal-open");
            },
            selectedCheck (role) {
                const tmpStatusList = this.statusList.map(item => {
                    const isExist = role.statuses.find(tmp => tmp.status_id === item.id)
                    const check = {
                        is_check : typeof isExist !== 'undefined' ? 1 : 0
                    }
                    return Object.assign({}, item, check)
                })
                const status_ids = role.statuses.map(tmp => { return tmp.status_id })
                return Object.assign(role, { statusList: tmpStatusList, status_id: status_ids })
            },
            async updateRole(){
                this.loader = true
                this.$store.dispatch('stateReload', true)
                const response = await config.postData('/roles/update', this.form_edit)
                this.loader = false
                if(response.status == 201){
                    this.roleEditModal = false;    
                    this.$store.dispatch('commonObjLoad', true)
                    this.$toast.success({
                        title: 'Success',
                        message: 'Role updated successfully',
                        color: '#218838'
                    })     
                    this.roleEditModal = false;       
                } else {
                    this.$refs.form.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })  
                }
            },
            deleteModal(key, rider_id){ 
                this.rider_id = rider_id;
                this.key = key;
                this.roleDeleteModal = true;
            },
            destroyRole(){
                config.getData('roles/destroy/'+ this.rider_id)
                .then(() => {
                    this.roles.splice(this.key, 1);
                    this.roleDeleteModal = false;
                    this.$toast.success('Role deleted successfully')
                })
                .catch((error) => {
                    this.$toast.error(error)  
                });
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>